import { useEffect } from 'react'
import { PATH_DASHBOARD } from '@/routes/paths'
import BoxCircularProgress from '@/components/common/BoxCircularProgress'
import useBrandSelect from '@/hooks/useBrandSelect'

const Home = () => {
    const { currentBrand } = useBrandSelect()

    useEffect(() => {
        if (currentBrand) {
            window.location.pathname = PATH_DASHBOARD.performance.root
        }
    }, [currentBrand])

    return <BoxCircularProgress />
}

export default Home
